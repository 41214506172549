import React, { useState } from "react"
import Tippy from "@tippyjs/react"
import "tippy.js/dist/tippy.css" // css for tippy
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import {
  statementConverter,
  getPersonsAndHightlights,
  getCommunityStories,
  convertStoryToMarkdown,
} from "../utility/converters"
import arrowLeft from "../images/arrow-left.svg"
import arrowRight from "../images/arrow-right.svg"
import CallForScroll from "../components/call-for-scroll"
import MailtoStoryButton from "../components/mailto-story-button"

const Stories = ({ data }) => {
  const statements = statementConverter(data.allStellingenCsv.edges)
  const [currentStatement, setStatement] = useState(
    Math.round(Math.random() * (statements.length - 1))
  )
  const [currentlyOpenModal, setCurrentlyOpenModal] = useState(null)
  // Change statement function
  const changeStatement = (direction = 1) => {
    if (direction > 0) {
      setStatement(
        currentStatement + direction < statements.length
          ? currentStatement + 1
          : 0
      )
    } else {
      setStatement(
        currentStatement + direction >= 0
          ? currentStatement - 1
          : statements.length - 1
      )
    }
  }

  const openModal = modalToOpen => {
    setCurrentlyOpenModal(modalToOpen)
  }

  const closeModal = () => {
    setCurrentlyOpenModal(null)
  }

  const personsAndHighlights = getPersonsAndHightlights(
    data.allPersonsCsv.edges,
    data.allStoriesCsv.edges,
    statements[currentStatement].highlights
  )

  // we split the arrat in two to have several persons above the statement and several below
  const half = Math.floor(personsAndHighlights.length / 2)
  const personsBelowStatement = personsAndHighlights.slice(0, half)
  const personsAboveStatement = personsAndHighlights.slice(
    half,
    personsAndHighlights.length
  )

  // get the community input

  const communityStories = getCommunityStories(
    data.allCommunitystoriesCsv.edges
  )
  //build subtitle
  let subtitleArray = []
  let subtitle = ""
  personsAndHighlights.forEach(person => {
    if (person.highlighted.length > 0) {
      subtitleArray.push({
        name: person.personInfo.name,
        highlight: person.highlighted[0].title,
      })
    }
  })

  if (subtitleArray.length > 0) {
    subtitleArray.forEach((person, index) => {
      if (index === 0) {
        subtitle = `Lees bijvoorbeeld het verhaal van ${person.name}`
      } else if (index === subtitleArray.length - 1 && index > 1) {
        subtitle = `${subtitle} & ${person.name}`
      } else {
        subtitle = `${subtitle}, ${person.name}`
      }
    })
  }

  return (
    <Layout>
      <div
        className={
          currentlyOpenModal !== null
            ? "stories stories--open-modal"
            : "stories"
        }
      >
        <Persons persons={personsAboveStatement} openModal={openModal} />
        <Statement
          changeStatement={changeStatement}
          statement={statements[currentStatement]}
          subtitle={subtitle}
        />
        <Indicator total={statements.length - 1} current={currentStatement} />
        <Persons
          persons={personsBelowStatement}
          openModal={openModal}
          communityStories={communityStories}
        />
        <Modals
          persons={personsAndHighlights}
          communityStories={communityStories}
          currentlyOpenModal={currentlyOpenModal}
          closeModal={closeModal}
        />
      </div>
    </Layout>
  )
}

/*
  stories page consists out of a statement with explanation that you can change
  Icons of people that have stories, people with stories that are highlighted with the currentStatement should be more visible
  modals for each person with all stories
*/
const MoreInfoTooltip = ({ content }) => (
  <Tippy arrow={true} content={<span>{content}</span>}>
    <button className="text-button more-info-button">- meer info -</button>
  </Tippy>
)

const Indicator = ({ total, current }) => {
  let indicator = []
  for (let i = 0; i <= total; i++) {
    if (i !== current) {
      indicator.push(<div key={i} className="indicator-circle"></div>)
    } else {
      indicator.push(
        <div
          key={i}
          className="indicator-circle indicator-circle--active"
        ></div>
      )
    }
  }
  return <div className="indicator">{indicator}</div>
}

const Statement = ({ changeStatement, statement, subtitle }) => (
  <div className="statement has-padding-x-small">
    <button className="svg-button" onClick={() => changeStatement(-1)}>
      <img className="svg-button_arrow" alt="arrow left" src={arrowLeft} />
      <p>vorige</p>
    </button>
    <div className="statement_text">
      <h1>{statement.statement}</h1>
      <MoreInfoTooltip content={statement.statementExplanation} />
      <p>{subtitle}</p>
    </div>
    <button className="svg-button" onClick={() => changeStatement(1)}>
      <img className="svg-button_arrow" alt="arrow right" src={arrowRight} />
      <p>Volgende</p>
    </button>
  </div>
)

const Persons = ({ persons, openModal, communityStories }) => {
  const renderPersons = persons.map((person, index) => {
    let highlighted = null
    if (person.highlighted.length > 0) {
      highlighted = person.highlighted[0]
    }
    return (
      <div key={person.personInfo.name} className="persons_person">
        <div className="person_image">
          <img
            className={`${person.personInfo.color}-bg${
              highlighted !== null ? " highlighted" : ""
            }`}
            src={`/images/persons/${person.personInfo.image}`}
            alt={person.personInfo.name}
          />
        </div>
        <div className="person_text">
          {highlighted ? (
            <h5>{person.personInfo.name}</h5>
          ) : (
            <h6>{person.personInfo.name}</h6>
          )}
          <p>{highlighted !== null && highlighted.title}</p>
          <button
            className={`text-button text-button--${person.personInfo.color}`}
            onClick={() => openModal(person.personInfo.id)}
          >
            Lees mijn verhaal
          </button>
        </div>
      </div>
    )
  })
  if (!communityStories) {
    return <div className="persons">{renderPersons}</div>
  }

  return (
    <div className="persons persons--with-comunity">
      {renderPersons}
      <div className="persons_person">
        <div className="person_image">
          <img
            className="green-bg highlighted"
            src="/images/persons/community.png"
            alt="community"
          />
        </div>
        <div className="person_text">
          <h5>De bieb van ons allemaal</h5>
          <p>Voeg jouw verhaal toe!</p>
          <button
            className="text-button text-button--green"
            onClick={() => openModal("community")}
          >
            Lees de verhalen
          </button>
        </div>
      </div>
    </div>
  )
}

const Modals = ({
  persons,
  currentlyOpenModal,
  closeModal,
  communityStories,
}) => {
  if (currentlyOpenModal === null) {
    return null
  }
  let stories
  let modalColor, modalImage, modalTitle
  if (currentlyOpenModal !== "community") {
    const person = persons[currentlyOpenModal]
    modalColor = person.personInfo.color
    modalImage = `/images/persons/${person.personInfo.image}`
    modalTitle = person.personInfo.name
    stories = person.stories.map((story, index) => (
      <div className="modal_story" key={index}>
        {index === 0 && <CallForScroll />}
        <div className="modal_story_text-and-image">
          <div className="modal_story_image">
            <img src={`/images/stories/${story.image}`} alt={story.title} />
          </div>
          <div className="modal_story_text has-margin-bottom-small">
            <p className="modal_story_date">
              <span
                className={`${person.personInfo.color}-text has-text-weight-bold`}
              >
                {story.title}
              </span>{" "}
              - {story.date}
            </p>
            {convertStoryToMarkdown(story.story)}
          </div>
        </div>
      </div>
    ))
  } else {
    modalColor = "blue"
    modalImage = "/images/persons/community.png"
    modalTitle = "Ons allemaal"
    stories = communityStories.map((story, index) => (
      <div className="modal_community-story" key={index}>
        <div className="modal_community-story_text">
          <p>
            <span className="blue-text has-text-weight-bold">
              Ingestuurd op:
            </span>{" "}
            {story.date}
          </p>
          {convertStoryToMarkdown(story.story)}
        </div>
      </div>
    ))
  }

  return (
    <>
      <ModalContent
        color={modalColor}
        image={modalImage}
        title={modalTitle}
        stories={stories}
        closeModal={closeModal}
      />
    </>
  )
}

const ModalContent = ({ color, image, title, stories, closeModal }) => (
  <div className={`modal light-${color}-bg`}>
    <div className="modal_content">
      <div className="modal_content_left">
        <div className="modal_content_left_title">
          <h2>De Bieb van:</h2>
        </div>
        <div className="modal_content_left_profile">
          <img className={`${color}-bg`} src={image} alt={title} />
          <h5>{title}</h5>
        </div>
        <div className="modal_content_left_close">
          <button className="svg-button" onClick={() => closeModal()}>
            <img
              className="svg-button_arrow"
              alt="arrow left"
              src={arrowLeft}
            />
            <p>Terug naar het overzicht</p>
          </button>
        </div>
      </div>
      <div className="modal_content_right">
        {stories}
        <div className="modal_cta">
          <div>
            <MailtoStoryButton />
            <br></br>
            <Link
              className="text-button small-text text-button--blue has-margin-top-tiny"
              to="/privacy/"
            >
              Privacy
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export const query = graphql`
  query StoryPageQuery {
    allStellingenCsv {
      edges {
        node {
          Uitleg_van_de_stelling
          Stelling
          Id_stelling
          Highlighted_stories
        }
      }
    }
    allStoriesCsv {
      edges {
        node {
          Datum
          Id_person
          Plaatje
          Stories
          Titel_stories
          Id_stories
        }
      }
    }
    allCommunitystoriesCsv {
      edges {
        node {
          Datum
          Stories
        }
      }
    }
    allPersonsCsv {
      edges {
        node {
          Naam
          Id_stories
          Plaatje
          Kleur
        }
      }
    }
  }
`

export default Stories
