import React from "react"
import { emailadres } from "../utility/strings"

const MailtoStoryButton = ({ extraClasses = "" }) => {
  const address = emailadres
  const subject = "Zo zie ik de bibliotheek van de toekomst"
  const alineas = [
    "Beste Bieb",
    "Graag deel ik met jullie hoe ik de dienstverlening van de bibliotheek voor me zie in 2025. Ik vertel jullie mijn verhaal met de bieb. Hierin staat wat ik graag zou doen met (behulp van) de bibliotheek en welke dingen ik belangrijk vind aan de bibliotheek. Je vindt alles hieronder:",
    "",
    "En wat me wel en niet aanspreekt in de verhalen van Charly, Yassir, Ed, Jeffrey en Paulien:",
    "",
  ]

  subject.replace(" ", "%20")
  const bodyContent = alineas.map(alinea => alinea.replace(" ", "%20"))
  const body = bodyContent.join("%0D%0A%0D%0A")
  return (
    <a
      className={`button ${extraClasses}`}
      href={`mailto:${address}?subject=${subject}&body=${body}`}
      rel="noopener noreferrer"
      target="_blank"
    >
      Deel jouw verhaal
    </a>
  )
}

export default MailtoStoryButton
